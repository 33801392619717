.transparent-navbar {
  background-color: transparent !important;
  z-index: 999;
}

.navbar-text5 {
  font-family: Inter;
}

/* For Inter font */
body {
  font-family: "Inter", sans-serif;
}

/* For Poppins font */
.navbar-nav .nav-link {
  font-family: "Poppins", sans-serif;
}

.button-main {
  gap: var(--dl-space-space-halfunit);
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-family: Inter;
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #af7852;
}
.button-main:hover {
  opacity: 0.75;
}

.navbar-nav .nav-link {
  font-weight: 600;
  text-align: center; /* Align links in the center */
}
.navbar-brand {
  margin-right: auto;
}

.phone {
  font-weight: bold !important;
}

@media (min-width: 992px) {
  .transparent-navbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
