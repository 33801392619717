.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  scroll-behavior: smooth;
}
.home-modal {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  z-index: 200;
  position: fixed;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  backdrop-filter: blur(4px);
  justify-content: center;
}
.home-practices {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  box-shadow: 0px 0px 25px -2px rgba(66, 68, 90, 0.4);
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.home-heading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.home-header {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.home-close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  transition: 0.3s;
}
.home-close:hover {
  opacity: 0.5;
}
.home-grid {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: grid;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
}
.home-section {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header01 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption {
  max-width: 385px;
  line-height: 24px;
}
.home-text {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image {
  width: 12px;
  object-fit: cover;
}
.home-section01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header02 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption01 {
  max-width: 385px;
  line-height: 24px;
}
.home-text001 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image01 {
  width: 12px;
  object-fit: cover;
}
.home-section02 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header03 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption02 {
  max-width: 385px;
  line-height: 24px;
}
.home-text002 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image02 {
  width: 12px;
  object-fit: cover;
}
.home-section03 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header04 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption03 {
  max-width: 385px;
  line-height: 24px;
}
.home-text003 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image03 {
  width: 12px;
  object-fit: cover;
}
.home-section04 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header05 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption04 {
  max-width: 385px;
  line-height: 24px;
}
.home-text004 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image04 {
  width: 12px;
  object-fit: cover;
}
.home-section05 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header06 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption05 {
  max-width: 385px;
  line-height: 24px;
}
.home-text005 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image05 {
  width: 12px;
  object-fit: cover;
}
.home-hero {
  width: 100%;
  height: 1413px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-main {
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 1440px;
  align-items: stretch;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-content {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}
.home-heading07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header07 {
  width: 100%;
  font-size: 62px;
  max-width: 750px;
  font-style: normal;
  font-weight: 600;
  line-height: 74px;
}
.home-caption06 {
  width: 100%;
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
}
.home-text006 {
  font-style: normal;
  font-weight: 800;
}
.home-icon1 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-image06 {
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: center;
}
.home-image07 {
  height: 95%;
  object-fit: cover;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  background-color: #af7852;
}
.home-content1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.home-section06 {
  position: relative;
}
.home-heading08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-header08 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.home-icon3 {
  width: 20px;
  object-fit: cover;
}
.home-text011 {
  color: rgb(255, 255, 255);
  width: 100%;
  max-width: 300px;
  line-height: 24px;
}
.home-divider {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: none;
}
.home-section07 {
  position: relative;
}
.home-heading09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-header09 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.home-icon4 {
  width: 20px;
  object-fit: cover;
}
.home-text012 {
  color: rgb(255, 255, 255);
  width: 100%;
  max-width: 300px;
  line-height: 24px;
}
.home-divider1 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: none;
}
.home-section08 {
  position: relative;
}
.home-heading10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-header10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.home-icon5 {
  width: 20px;
  object-fit: cover;
}
.home-text016 {
  color: rgb(255, 255, 255);
  width: 100%;
  max-width: 300px;
  line-height: 24px;
}
.home-divider2 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: none;
}
.home-background {
  right: 0px;
  width: 37%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #D0F4FD;
}
.home-practices1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  margin-top: 131px;
  align-items: flex-start;
  padding-top: 38px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: 120px;
  flex-direction: row;
  padding-bottom: 120px;
}
.home-heading11 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text022 {
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
}
.home-text023 {
  font-size: 18px;
  max-width: 385px;
  line-height: 27px;
}
.home-content2 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-grid1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: grid;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
}
.home-practice-wrapper {
  cursor: pointer;
  text-decoration: none;
}
.home-practice {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header11 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption07 {
  max-width: 385px;
  line-height: 24px;
}
.home-text025 {
  line-height: 24px;
}
.home-text028 {
  line-height: 24px;
}
.home-practice-wrapper1 {
  cursor: pointer;
  text-decoration: none;
}
.home-practice1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header12 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption08 {
  max-width: 385px;
  line-height: 24px;
}
.home-text032 {
  line-height: 24px;
}
.home-text035 {
  line-height: 24px;
}
.home-practice-wrapper2 {
  cursor: pointer;
  text-decoration: none;
}
.home-practice2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header13 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption09 {
  max-width: 385px;
  line-height: 24px;
}
.home-text039 {
  line-height: 24px;
}
.home-text042 {
  line-height: 24px;
}
.home-practice-wrapper3 {
  cursor: pointer;
  text-decoration: none;
}
.home-practice3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header14 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption10 {
  max-width: 385px;
  line-height: 24px;
}
.home-text046 {
  line-height: 24px;
}
.home-text049 {
  line-height: 24px;
}
.home-practice-wrapper4 {
  cursor: pointer;
  text-decoration: none;
}
.home-practice4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header15 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption11 {
  max-width: 385px;
  line-height: 24px;
}
.home-text053 {
  line-height: 24px;
}
.home-text056 {
  line-height: 24px;
}
.home-features1 {
  gap: 120px;
  width: 100%;
  display: none;
  max-width: 1440px;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  background-color: var(--dl-color-primary-700);
}
.home-section09 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-content3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading17 {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
}
.home-capton {
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
}
.home-text059 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image08 {
  width: 12px;
  object-fit: cover;
}
.home-image09 {
  width: 400px;
  object-fit: cover;
}
.home-section10 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.home-content4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading18 {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
}
.home-capton1 {
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
}
.home-text060 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image10 {
  width: 12px;
  object-fit: cover;
}
.home-image11 {
  width: 358px;
  height: 447px;
  object-fit: cover;
}
.home-section11 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-content5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading19 {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
}
.home-capton2 {
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
}
.home-image12 {
  width: 580px;
  object-fit: cover;
}
.home-book2 {
  display: none;
}
.home-schedule {
  width: 100%;
  display: none;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
}
.home-content6 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: column;
  background-color: #af7852;
}
.home-header19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading20 {
  color: #ffffff;
  font-size: 56px;
  max-width: 800px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-caption12 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  max-width: 900px;
  text-align: center;
  line-height: 27px;
}
.home-types {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-book-person {
  text-decoration: none;
}
.home-search {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: none;
  max-width: 1440px;
  align-items: stretch;
  border-color: #B4B9C6;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: 110px;
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-heading21 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text065 {
  font-size: 56px;
  max-width: 385px;
  font-style: normal;
  font-weight: 600;
}
.home-text066 {
  font-size: 18px;
  max-width: 385px;
  line-height: 27px;
}
.home-content7 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-type-one {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #B4B9C6;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  border-bottom-width: 1px;
}
.home-alphabet {
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, minmax(60px, 1fr));
}
.home-text067 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text068 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text069 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text070 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text071 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text072 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text073 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text074 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text075 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text076 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text077 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text078 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text079 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text080 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text081 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text082 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text083 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text084 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text085 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text086 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text087 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text088 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text089 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text090 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text091 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text092 {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-text093 {
  max-width: 700px;
  line-height: 24px;
}
.home-results {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading22 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-list {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-type-two {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading23 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text094 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}
.home-text095 {
  max-width: 700px;
  line-height: 24px;
}
.home-symptoms {
  gap: var(--dl-space-space-unit);
  flex: 1;
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-row {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text096 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text097 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text098 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text099 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text100 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-row1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text101 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text102 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text103 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text104 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text105 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-row2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text106 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text107 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text108 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text109 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text110 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
@media(max-width: 991px) {
  .home-hero {
    flex: 1;
    height: 0px;
  }
  .home-main {
    flex-direction: column;
  }
  .home-content {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-header07 {
    max-width: 75%;
  }
  .home-caption06 {
    max-width: 75%;
  }
  .home-image06 {
    flex: 0 0 auto;
    align-items: flex-end;
    padding-right: var(--dl-space-space-twounits);
    justify-content: flex-end;
  }
  .home-features {
    padding-top: var(--dl-space-space-fourunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content1 {
    flex-direction: column;
  }
  .home-section06 {
    border-right-width: 0px;
  }
  .home-divider {
    width: 80px;
    height: 1px;
    display: flex;
    border-color: #ffffff;
    border-width: 0px;
    background-color: #ffffff;
  }
  .home-section07 {
    border-right-width: 0px;
  }
  .home-divider1 {
    width: 80px;
    height: 1px;
    display: flex;
    border-color: #ffffff;
    border-width: 0px;
    background-color: #ffffff;
  }
  .home-section08 {
    border-right-width: 0px;
  }
  .home-divider2 {
    width: 80px;
    height: 1px;
    display: flex;
    border-color: #ffffff;
    border-width: 0px;
    background-color: #ffffff;
  }
  .home-background {
    left: 0px;
    width: 100%;
    bottom: 0px;
    height: 55%;
    position: absolute;
    justify-content: flex-end;
  }
  .home-practices1 {
    flex-direction: column;
  }
  .home-features1 {
    gap: var(--dl-space-space-threeunits);
    height: 3193px;
  }
  .home-section09 {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-image09 {
    width: 100%;
  }
  .home-section10 {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-image11 {
    width: 100%;
  }
  .home-section11 {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-image12 {
    width: 100%;
  }
  .home-schedule {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-types {
    width: 100%;
    flex-direction: column;
  }
  .home-book-person {
    width: 50%;
  }
  .home-book-person1 {
    width: 50%;
  }
  .home-search {
    width: 100%;
    flex-direction: column;
  }
  .home-heading21 {
    align-items: center;
  }
  .home-text065 {
    max-width: 100%;
    text-align: center;
  }
  .home-text066 {
    text-align: center;
  }
  .home-content7 {
    gap: var(--dl-space-space-threeunits);
  }
  .home-type-one {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    align-items: center;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: center;
  }
  .home-alphabet {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .home-text093 {
    text-align: center;
  }
  .home-type-two {
    gap: var(--dl-space-space-twounits);
  }
  .home-heading23 {
    align-items: center;
  }
  .home-text094 {
    text-align: center;
  }
  .home-text095 {
    text-align: center;
  }
  .home-symptoms {
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .home-row {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  .home-row1 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-row2 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .home-main {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-end;
    padding-left: var(--dl-space-space-oneandhalfunits);
    justify-content: center;
  }
  .home-header07 {
    font-size: 32px;
    max-width: 70%;
    line-height: 38px;
  }
  .home-caption06 {
    font-size: 16px;
    max-width: 70%;
    line-height: 24px;
  }
  .home-content1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-header08 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-text011 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header09 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-text012 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header10 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-text016 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text017 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-practices1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading11 {
    gap: var(--dl-space-space-unit);
  }
  .home-text022 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-text023 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-content2 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
  }
  .home-grid1 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  .home-practice {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-header11 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-caption07 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text024 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text026 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text027 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text029 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text030 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-practice1 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-header12 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-caption08 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text031 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text033 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text034 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text036 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text037 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-practice2 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-header13 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-caption09 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text038 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text040 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text041 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text043 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text044 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-practice3 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-header14 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-caption10 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text045 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text047 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text048 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text050 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text051 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-practice4 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-header15 {
    font-size: 20px;
    line-height: 24px;
  }
  .home-caption11 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text052 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text054 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text055 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text057 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-text058 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content3 {
    gap: var(--dl-space-space-unit);
  }
  .home-heading17 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-capton {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-heading18 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-capton1 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-heading19 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-capton2 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-book1 {
    display: none;
  }
  .home-book2 {
    width: 100%;
    display: flex;
  }
  .home-schedule {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content6 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading20 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-caption12 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-types {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-book-person {
    width: 80%;
  }
  .home-book-person1 {
    width: 80%;
  }
  .home-search {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading21 {
    gap: var(--dl-space-space-unit);
  }
  .home-text065 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-text066 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-alphabet {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-symptoms {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .home-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-header07 {
    max-width: 80%;
  }
  .home-caption06 {
    max-width: 100%;
  }
  .home-book {
    width: 100%;
  }
  .home-image07 {
    max-height: 500px;
  }
  .home-practices1 {
    margin-top: 0px;
  }
}
