body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.team-header {
  background: linear-gradient(to right, #d6aaaa, #9fa5a2);
  padding: 40px 20px;
  color: #fff;
}

.team-header h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.leader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.leader img {
  width: 250px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}

.quote {
  max-width: 600px;
  text-align: left;
}

.quote blockquote {
  font-size: 1.2em;
  font-style: italic;
  margin: 0 auto 20px;
}

.leader-name {
  font-weight: bold;
  margin-top: 10px;
  font-size: 1.2em;
  text-align: left;
}

.leader-title {
  margin-top: 5px;
  font-style: italic;
  font-size: 1em;
  text-align: left;
}

.board-members {
  background-color: #fff;
  display: flex;
  justify-content: center; /* Center the items horizontally */
  padding: 40px 20px;
  gap: 20px; /* Add gap to ensure spacing between members */
  flex-wrap: wrap; /* Ensure that members wrap if there's not enough space horizontally */
}

.member {
  text-align: center; /* Center text and image within the member container */
  flex: 0 1 200px; /* Allow member divs to take equal space and have a min-width */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items vertically within the member container */
}

.member img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.member-name {
  font-weight: bold;
  margin-top: 10px;
  font-size: 1.1em;
}

.member-title {
  margin-top: 5px;
  font-style: italic;
  font-size: 0.9em;
}


/* Responsive Design */
@media (max-width: 768px) {
  .leader {
    flex-direction: column;
  }

  .leader img {
    width: 200px;
    height: 250px;
  }

  .quote {
    text-align: center;
  }

  .board-members {
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Ensure proper spacing in mobile view */
  }

  .member img {
    width: 100px;
    height: 100px;
  }
}
