.news-news {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 200px;
}
.news-heading {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.news-text {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.news-text1 {
  font-size: 18px;
  max-width: 700px;
  text-align: center;
  line-height: 27px;
}
.news-list {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  flex-direction: row;
}
.news-navlink {
  display: contents;
}
.news-item {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  background-color: #af7852;
}
.news-image {
  width: 100%;
  height: 275px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.news-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-content {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.news-details {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.news-date {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.news-quick-description {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.news-text2 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.news-image2 {
  width: 12px;
  object-fit: cover;
}
.news-navlink1 {
  display: contents;
}
.news-item1 {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  background-color: #F5F5F7;
}
.news-image3 {
  width: 100%;
  height: 274px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.news-image4 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-content1 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.news-details1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.news-date1 {
  color: #000000;
  line-height: 24px;
}
.news-quick-description1 {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.news-text3 {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.news-image5 {
  width: 12px;
  object-fit: cover;
}
.news-navlink2 {
  display: contents;
}
.news-item2 {
  width: 100%;
  height: 640px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  background-color: #F5F5F7;
}
.news-image6 {
  width: 100%;
  height: 274px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.news-image7 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-content2 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.news-details2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.news-date2 {
  color: #000000;
  line-height: 24px;
}
.news-quick-description2 {
  color: rgb(0, 0, 0);
  height: 216px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.news-text4 {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.news-image8 {
  width: 12px;
  object-fit: cover;
}

@media(max-width: 991px) {
  .news-list {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .news-item {
    height: auto;
  }
  .news-image {
    height: 280px;
  }
  .news-content {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .news-quick-description {
    font-size: 18px;
  }
  .news-item1 {
    height: auto;
  }
  .news-image3 {
    height: 280px;
  }
  .news-content1 {
    gap: var(--dl-space-space-twounits);
    padding: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .news-quick-description1 {
    font-size: 18px;
  }
  .news-item2 {
    height: auto;
  }
  .news-image6 {
    height: 280px;
  }
  .news-content2 {
    gap: var(--dl-space-space-twounits);
    padding: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .news-quick-description2 {
    font-size: 18px;
  }
}
@media(max-width: 767px) {
  .news-news {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .news-heading {
    gap: var(--dl-space-space-unit);
  }
  .news-text {
    font-size: 30px;
    line-height: 36px;
  }
  .news-text1 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .news-date {
    font-size: 10px;
    line-height: 15px;
  }
  .news-date1 {
    font-size: 10px;
    line-height: 15px;
  }
  .news-date2 {
    font-size: 10px;
    line-height: 15px;
  }
}
